import { DocumentsActions } from '@app/core/entities/actions';
import { getDocuments, getDocumentsLoading } from '@app/core/entities/reducers';
import { Document } from '@app/core/models/document';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export abstract class DocumentsBaseComponent {
  documents$: Observable<Document[]>;
  isLoading$: Observable<boolean>;

  protected constructor(
    protected readonly _store: Store<fromRoot.State>,
    protected readonly _translocoService: TranslocoService
  ) {
    this.documents$ = this._store.select(getDocuments);
    this.isLoading$ = this._store.select(getDocumentsLoading);
  }

  downloadDocument(document: Document): void {
    this._store.dispatch(DocumentsActions.downloadDocument({ document }));
  }
}
